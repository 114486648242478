import React, { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";





import table14 from "./assets/model14.glb";
import table15 from "./assets/model15.glb";

import table19 from "./assets/model19.glb";

import table30 from "./assets/model30.glb";

import table34 from "./assets/model34.glb";
import table35 from "./assets/model35.glb";
import table36 from "./assets/model36.glb";
import table37 from "./assets/model37.glb";

import "./styles.css";

import Logo from "./assets/timeartlogo.png";
import Image1 from "./assets/1.jpg";
import Image2 from "./assets/2.jpg";
import Image3 from "./assets/3.jpg";
import Image4 from "./assets/5.jpg";
import Image5 from "./assets/4.jpg";
import Image6 from "./assets/6.jpg";
import Image7 from "./assets/7.jpg";
import Image8 from "./assets/8.jpg";
import Image9 from "./assets/9.jpg";
import Image10 from "./assets/10.jpg";
import Image11 from "./assets/11.jpg";
import Image12 from "./assets/12.jpg";
import Image13 from "./assets/13.jpg";
import Image14 from "./assets/14.jpg";
import Image15 from "./assets/15.jpg";
import Image16 from "./assets/16.jpg";
import Image17 from "./assets/17.jpg";
import Image18 from "./assets/18.jpg";
import Image19 from "./assets/19.jpg";
import Image20 from "./assets/20.jpg";
import Image21 from "./assets/21.jpg";
import Image22 from "./assets/22.jpg";
import Image23 from "./assets/23.jpg";
import Image24 from "./assets/24.jpg";
import Image25 from "./assets/25.jpg";
import Image26 from "./assets/26.jpg";
import Image27 from "./assets/27.jpg";
import Image28 from "./assets/28.jpg";
import Image29 from "./assets/29.jpg";
import Image30 from "./assets/30.jpg";
import Image31 from "./assets/31.jpg";
import Image32 from "./assets/32.jpg";
import Image33 from "./assets/33.jpg";
import Image34 from "./assets/34.jpg";
import Image35 from "./assets/35.jpg";
import Image36 from "./assets/36.jpg";
import Image37 from "./assets/37.jpg";
import Image38 from "./assets/38.jpg";
import Image39 from "./assets/39.jpg";
import Image40 from "./assets/40.jpg";
import Image41 from "./assets/41.jpg";
import Image42 from "./assets/42.jpg";
import Image43 from "./assets/43.jpg";
import Image44 from "./assets/44.jpg";
import Image45 from "./assets/45.jpg";
import Image46 from "./assets/46.jpg";
import Image47 from "./assets/47.jpg";
import Image48 from "./assets/48.jpg";
import Image49 from "./assets/49.jpg";
import Image50 from "./assets/50.jpg";
import Image51 from "./assets/51.jpeg";
import Image52 from "./assets/52.jpeg";
import Image53 from "./assets/53.jpeg";

import Image54 from "./assets/54.jpeg";
import Image55 from "./assets/53.jpeg";
import Image56 from "./assets/56.jpeg";
import Image57 from "./assets/57.jpeg";
import Image58 from "./assets/58.jpeg";
import Image59 from "./assets/59.jpeg";
import Image60 from "./assets/60.jpeg";
import Image61 from "./assets/61.jpeg";
import Image62 from "./assets/62.jpg";
import Image63 from "./assets/63.jpeg";
import Image64 from "./assets/64.jpeg";
import Image65 from "./assets/65.jpeg";
import Image66 from "./assets/66.jpg";
import Image67 from "./assets/67.jpg";
import Image68 from "./assets/68.png";



let container, camera, scene, renderer, orbitControls, modelSelector, textureSelector, colorSelector;
const materials = {};  // Materyalleri her bir mesh için saklamak

const setupScene = () => {
  scene = new THREE.Scene();
};

const setupCamera = () => {
  camera = new THREE.PerspectiveCamera(20, container.offsetWidth / container.offsetHeight, 1e-5, 1e10);
  scene.add(camera);
};

const setupLights = () => {
  // Mevcut hemisferik ışığın yoğunluğunu artırıyoruz
  const hemispheric = new THREE.HemisphereLight(0xffffff, 0x222222, 1.5); // Yoğunluğu artırıldı (1'den 1.5'a)
  scene.add(hemispheric);

  // Yeni bir yönlü ışık ekliyoruz
  const directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Yönlü ışık ekleniyor, yoğunluk 2
  directionalLight.position.set(5, 10, 7.5); // Işığın pozisyonu ayarlanıyor
  scene.add(directionalLight);

  // Işığın gölgeleri ayarlamak için (gölge isteniyorsa)
  directionalLight.castShadow = true;
  directionalLight.shadow.camera.near = 0.1;
  directionalLight.shadow.camera.far = 100;
  directionalLight.shadow.camera.left = -50;
  directionalLight.shadow.camera.right = 50;
  directionalLight.shadow.camera.top = 50;
  directionalLight.shadow.camera.bottom = -50;
  directionalLight.shadow.mapSize.width = 1024;
  directionalLight.shadow.mapSize.height = 1024;
};


const setupRenderer = () => {
  renderer = new THREE.WebGLRenderer({ antialias: true, logarithmicDepthBuffer: true });
  renderer.setClearColor(0xffffff);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
 // renderer.outputEncoding = THREE.sRGBEncoding;
  container.appendChild(renderer.domElement);
};

const loadModel = (modelPath) => {
  const loader = new GLTFLoader();
  loader.load(modelPath, (gltf) => {
    const object = gltf.scene;
    setupModel(object);
    scene.add(object);
    setupOrbitControls();  // OrbitControls burada başlatılıyor
    onWindowResize(); // Yüklemede ilk yeniden boyutlandırma
  });
};

const setupModel = (object) => {
  object.updateMatrixWorld();
  const boundingBox = new THREE.Box3().setFromObject(object);
  const modelSizeVec3 = new THREE.Vector3();
  boundingBox.getSize(modelSizeVec3);
  const modelSize = modelSizeVec3.length();
  const modelCenter = new THREE.Vector3();
  boundingBox.getCenter(modelCenter);

  object.position.x = -modelCenter.x;
  object.position.y = -modelCenter.y;
  object.position.z = -modelCenter.z;

  // Kameranın pozisyonunu ayarlamak
  const distanceFactor = 4;  // Bu değeri artırarak modeli daha uzaktan görebilirsiniz
  camera.position.set(modelCenter.x, modelCenter.y + modelSize * 0.5, modelCenter.z + modelSize * distanceFactor);
  camera.near = modelSize / 100;
  camera.far = modelSize * 100;
  camera.updateProjectionMatrix();
  camera.lookAt(modelCenter);

  // Materyalleri temizle
  for (let key in materials) {
    delete materials[key];
  }

  // Objeyi gezip her bir mesh için materyalleri sakla
  object.traverse((obj) => {
    if (obj.isMesh) {
      console.log("Mesh adı:", obj.name);
      materials[obj.name] = obj.material;
    }
  });

  // Mevcut doku ve rengi yeni materyallere uygula
  const currentTexture = convertImageToTexture(textureSelector.value);
  const currentColor = colorSelector.value;
  for (let key in materials) {
    materials[key].map = currentTexture;
    materials[key].color.set(currentColor);
    materials[key].needsUpdate = true;
  }
};

const setupOrbitControls = () => {
  orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.reset();
  orbitControls.maxDistance = 50;
  orbitControls.enableDamping = true;
  orbitControls.dampingFactor = 0.07;
  orbitControls.rotateSpeed = 1.25;
  orbitControls.panSpeed = 1.25;
  orbitControls.screenSpacePanning = true;
  orbitControls.autoRotate = true;
};

const onWindowResize = () => {
  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
};

const convertImageToTexture = (image) => {
  const textureLoader = new THREE.TextureLoader();
  let texture = textureLoader.load(image);
  texture.colorSpace = THREE.SRGBColorSpace;
  texture.flipY = false;
  return texture;
};

const init = (modelPath) => {
  setupScene();
  setupCamera();
  setupLights();
  setupRenderer();
  loadModel(modelPath);
};

const ThreeJSExample = () => {
  const animate = useCallback(() => {
    requestAnimationFrame(animate);
    if (orbitControls) orbitControls.update();
    renderer.render(scene, camera);
  }, []);

  const ref = useRef(null);
  const modelRef = useRef(null);
  const textureRef = useRef(null);
  const colorRef = useRef(null);

  useEffect(() => {
    container = ref.current;
    modelSelector = modelRef.current;
    textureSelector = textureRef.current;
    colorSelector = colorRef.current;

    const savedModel = sessionStorage.getItem("selectedModel") || table14;
    modelSelector.value = savedModel;

    const savedTexture = sessionStorage.getItem("selectedTexture") || Image1;
    const savedColor = sessionStorage.getItem("selectedColor") || "#ffffff";

    textureSelector.value = savedTexture;
    colorSelector.value = savedColor;

    const resizeHandler = () => onWindowResize();

    init(savedModel);
    animate();

    window.addEventListener("resize", resizeHandler, false);

    // Model seçim değişikliğinde modeli güncelle
    modelSelector.addEventListener("change", (event) => {
      const selectedModel = event.target.value;
      sessionStorage.setItem("selectedModel", selectedModel);
      window.location.reload();
    });

    colorSelector.addEventListener("input", (event) => {
      const color = event.target.value;
      for (let key in materials) {
        materials[key].color.set(color);
        materials[key].needsUpdate = true;
      }
      sessionStorage.setItem("selectedColor", color);
    });

    return () => {
      window.removeEventListener("resize", resizeHandler, false);
    };
  }, [animate]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [
    Image67,Image66,Image68,
    Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10,
    Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20,
    Image21, Image22, Image23, Image24, Image25, Image26, Image27, Image28, Image29, Image30,
    Image31, Image32, Image33, Image34, Image35, Image36, Image37, Image38, Image39, Image40,
    Image41, Image42, Image43, Image44, Image45, Image46, Image47, Image48, Image49, Image50,
    Image51,Image52,Image53, Image54, Image55, Image56, Image57, Image58, Image59, Image60,
    Image61,Image62,Image63, Image64, Image65,

  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % (images.length - 4));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length - 4) % (images.length - 4));
  };

  const selectTexture = (image) => {
    const texture = convertImageToTexture(image);
    for (let key in materials) {
      materials[key].map = texture;
      materials[key].needsUpdate = true;
    }
    sessionStorage.setItem("selectedTexture", image);
  };

  const handleLikeClick = () => {
    alert("Beğenilenlere eklendi!");
  };

 
  return (
    <div>
      <header className="header">
        <img className="logo" src={Logo} alt="Logo" />
        <div className="header-content">
          <div className="links">
            <a href="#home">Beğendiklerim</a>
            
            <a href="#services">Katalog</a>
          </div>
        </div>
      </header>

     
      <div className="preview">
        <div className="controls">
        <div className="filters">
        <label>
          <input type="checkbox" id="patternCheckbox" />
          Desen
        </label>
        <label>
          <input type="checkbox" id="fabricCheckbox" />
          Kumaş
        </label>
      </div>
      
          <div className="model">
            <p>Model</p>
            <select id="modelSelector" ref={modelRef}>
          

              <option value={table14}>Model 1</option>
              <option value={table15}>Model 2</option>
          
              <option value={table19}>Model 3</option>
          
              <option value={table30}>Model 4</option>
              
			  <option value={table34}>Model 5</option>
              <option value={table35}>Model 6</option>
              <option value={table36}>Model 7</option>
              <option value={table37}>Model 8</option>
            </select>
          </div>
          <div className="texture">
            <p>Texture</p>
            <div className="carousel" ref={textureRef}>
              <button className="carousel-button" onClick={prevSlide}>{"<"}</button>
              {images.slice(currentSlide, currentSlide + 4).map((image, index) => (
  <label key={index} onClick={() => selectTexture(image)}>
    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
    <img src={image} alt={`Image ${currentSlide + index + 1}`} className="image-preview" />
    <input type="radio" name="texture" value={image} hidden />
  </label>
))}

              <button className="carousel-button" onClick={nextSlide}>{">"}</button>
            </div>
          </div>
          
          <div className="color">
            <p>Color</p>
            <input type="color" id="colorPicker" name="favcolor" defaultValue="whitesmoke" ref={colorRef} />
          </div>
          <div className="actions">
            <button className="like-button" onClick={handleLikeClick}>Beğen</button>
           
          </div>
        </div>

        <div className="ref" ref={ref} />
      </div>
      <footer className="footer">
        <p>&copy; 2024 Timeart</p>
      </footer>
    </div>
  );
};

export default ThreeJSExample;


